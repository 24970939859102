// Generated by Framer (0961f6c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["feb0VWtx6", "IzUT2pUJP"];

const serializationHash = "framer-1Hdip"

const variantClassNames = {feb0VWtx6: "framer-v-q1jv5b", IzUT2pUJP: "framer-v-1jut7ap"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "feb0VWtx6", Phone: "IzUT2pUJP"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "feb0VWtx6"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "feb0VWtx6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-q1jv5b", className, classNames)} data-border data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"feb0VWtx6"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgb(34, 34, 34)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(17, 17, 17)", borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, ...style}} variants={{IzUT2pUJP: {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px", backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}} {...addPropertyOverrides({IzUT2pUJP: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1Hdip.framer-870m95, .framer-1Hdip .framer-870m95 { display: block; }", ".framer-1Hdip.framer-q1jv5b { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: flex-start; min-height: 108px; padding: 15px; position: relative; width: 135px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1Hdip.framer-q1jv5b { gap: 0px; } .framer-1Hdip.framer-q1jv5b > * { margin: 0px; margin-bottom: calc(5px / 2); margin-top: calc(5px / 2); } .framer-1Hdip.framer-q1jv5b > :first-child { margin-top: 0px; } .framer-1Hdip.framer-q1jv5b > :last-child { margin-bottom: 0px; } }", ".framer-1Hdip.framer-v-1jut7ap.framer-q1jv5b { gap: 0px; min-height: 200px; padding: 32px 0px 0px 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1Hdip.framer-v-1jut7ap.framer-q1jv5b { gap: 0px; } .framer-1Hdip.framer-v-1jut7ap.framer-q1jv5b > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-1Hdip.framer-v-1jut7ap.framer-q1jv5b > :first-child { margin-top: 0px; } .framer-1Hdip.framer-v-1jut7ap.framer-q1jv5b > :last-child { margin-bottom: 0px; } }", ".framer-1Hdip[data-border=\"true\"]::after, .framer-1Hdip [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 107.5
 * @framerIntrinsicWidth 135
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"IzUT2pUJP":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQtgs0gJwM: React.ComponentType<Props> = withCSS(Component, css, "framer-1Hdip") as typeof Component;
export default FramerQtgs0gJwM;

FramerQtgs0gJwM.displayName = "Links: Product Copy 3";

FramerQtgs0gJwM.defaultProps = {height: 107.5, width: 135};

addPropertyControls(FramerQtgs0gJwM, {variant: {options: ["feb0VWtx6", "IzUT2pUJP"], optionTitles: ["Desktop", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerQtgs0gJwM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})